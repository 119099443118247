    
    .filter__popover{
        width: 450px;
        color: #252525;
        font-family: "Montserrat";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .popover__date{
        display: flex;
        flex-direction: column;
        margin-bottom: 0px !important;
        gap: 10px;
    }

    .ant-popover-inner{
        border-radius: 8px !important;
    }