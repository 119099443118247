.no_apiKey--wrapper {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &-inner {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        .main__title {
            font-family: "Montserrat";
            font-size: 20px;
            font-weight: 500;
            color: #979797;
        }
        .information {
            color: #979797;
            font-family: "Montserrat";
            font-size: 14px;
            font-weight: 500;
            width: 60%;
            text-align: center;
        }
        &-btn {
            background: linear-gradient(94.98deg, #6C1EB0 0%, #400077 100%);
            border-radius: 10px;
            padding: 12px 100px;
            color: #fff;
            font-family: "Montserrat";
            font-size: 16px;
            font-weight: 600;
            cursor: pointer;
        }
    }
}