//Parent
.ticketSupport__container {
  display: flex;
  justify-content: space-between;

   &-loading{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

//Header Actions
.ticketSupport__headerActions--container {
  display: flex;
  gap: 12px;
  padding-right: 35px;
}

.header__component {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: #FFFFFF;
  height: 60px;
  border-bottom: 1px solid #EFEFEF;
  flex-wrap: wrap;

  &-loader {
    margin: 0 auto 0 40px;
  }

  @media screen and (max-width: 1300px) {
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding-bottom: 10px;
  }

  &-wrapper {
    display: flex;
    gap: 10px;
    min-width: 130px;
    margin-bottom: 5px;
    align-items: center;
    height: 100%;
    border-right: 1px solid #EFEFEF;
    padding-right: 10px;
  }

  &-text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #6C1EB0;
  }

  .dashboard__left__text-header {
    @media screen and (max-width : 762px) {
      width: 100vw;
      height: fit-content;
      margin: 10px 0 30px 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

//Modal

.createTicket__container {
  color: #252525;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .MuiInputBase-input{
    font-family: "Montserrat" !important;
    font-size: 14px !important;
  }
 .rdw-link-modal{
  height: 235px !important;
 }

  &--title {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .secondStep__input{
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  &--description {
    display: flex;
    flex-direction: column;
    gap: 10px;
    

    &-wraper {
      &.rdw-editor-wrapper {
        display: flex !important;
        flex-direction: column-reverse !important;

      }
    }

    &-draft {
      margin-top: unset !important;
    }

    &-draft .rdw-editor-toolbar {
      border-radius: unset;
      border-bottom-right-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
    }

    &-draft .rdw-editor-main {
      border: unset !important;
      border-top: 1px solid #cacaca !important;
      border-right: 1px solid #cacaca !important;
      border-left: 1px solid #cacaca !important;
      min-height: 150px !important;
      border-top-right-radius: 8px !important;
      border-top-left-radius: 8px !important;
    }
  }

  &--fileUpload{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }



  &--urgency {
    display: flex;
    flex-direction: column;
    gap: 5px;

    &-renderOption{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }
    
  }

  &--uploadFile {
    width: 100%;
    // height: 100px;
    padding: 31px 0px;
    border-radius: 8px;
    border: 1px dashed #6C1EB0;
    background: rgba(108, 30, 176, 0.10);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;

    &-text {
      color: #252525;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  &--createButton {
    &-button {
      font-size: 16px !important;
      font-style: normal !important;
      font-weight: 600 !important;
      line-height: normal !important;
    }
  }

  &--uploadedFiles {
    margin-top: 10px;
    margin-bottom: 5px;

    &-files {


      &__displayed {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #E8E8E8;
        padding: 4px;
        color: #979797;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-top: 10px;

        &_name {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
        }

        &_mbX {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
        }
      }
    }

  }

  &--category {
    display: flex;
    flex-direction: column;
    gap: 10px;
 
    &-renderOption{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }
    
  }

  &--dateContainer {
    &-items {
      display: flex;
      flex-direction: column;
      margin-bottom: 34px;
      gap: 10px;

      .ant-picker{
        padding: 9.5px 11px 9.5px !important;
        border-radius: 8px !important;
        height: 50px;
      }

    }
  }

  &--dropdown{
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

}

//Button

.createuserButton{
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.createTicket__button {
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}

.ticket__modalContainer {
  height: calc(100vh - 40px);
  overflow: auto;
}

.ticket__modalContainer::-webkit-scrollbar {
  display: none;
}

.uploadFile__style {
  display: flex;
  height: 50px;
  padding: 16px 25px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  background: linear-gradient(95deg, #6C1EB0 0%, #400077 100%);
  color: #FFFFFF;
  cursor: pointer;
}

.ticketHeader__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  color: #6C1EB0;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.ticket-modal {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  // height: 100vh;
  max-height: 100vh;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-icon {
      cursor: pointer;
    }

    &-title {
      color: #6c1eb0;
      font-family: 'Montserrat';
      font-size: 20px;
      margin: 0 auto;
      font-weight: 700;
    }
  }

  &-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    height: 100%;

    .form_input {
      .form_input__label {
        color: #252525;
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 600;
        color: #252525;
      }
    }

    &-colorPicker {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;

      &-label {
        color: #252525;
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 600;
      }

      &-indicator {
        padding: 0 20px 0 5px;
        display: flex;
        gap: 10px;
        justify-content: space-between;
        align-items: center;

        .color--indicator {
          align-self: flex-start;
          width: 20px;
          height: 20px;
          border-radius: 5px;
          margin-top: 40px;
        }

        .hex--input {
          display: flex;
          flex-direction: column;
          gap: 5px;
          align-self: flex-start;

          &__label {
            color: #252525;
            font-family: 'Montserrat';
            font-size: 14px;
            font-weight: 600;
          }

          input {
            border-radius: 8px;
            border: 1px solid #e8e8e8;
            background: #fff;
            padding: 10px;
            text-align: center;
          }
        }
      }

      &-error {
        font-family: 'Montserrat';
        display: block;
        margin-top: 5px;
        margin-left: 2px;
        color: red;
        font-size: 12px;
      }
    }

    & .primaryButton {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 46px;
      margin-top: 10px;
      background-color: #6c1eb0;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      font-weight: 600;
    }

    .status-dropdown {
      &__label {
        color: #252525;
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 600;
        padding-bottom: 8.5px;
      }

      .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
        margin-bottom: 3px;
      }
    }
  }
}

.draftContent {
  margin-top: 20px;

  .rdw-editor-toolbar {
    padding: 10px 5px;
    // padding: 6px 5px 0;
    // border-radius: 2px;
    border: 1px solid #cacaca;
    display: flex;
    justify-content: flex-start;
    background: white;
    flex-wrap: wrap;
    font-size: 15px;
    margin-bottom: 5px;
    // -webkit-user-select: none;
    user-select: none;
  }

  .rdw-option-wrapper {
    height: unset;

    img {
      padding: 5px;
    }
  }

  .rdw-editor-main {
    min-height: 250px;
    background-color: white;
    border: 1px solid #f5f5f5;
    padding: 10px;
  }

  .public-DraftStyleDefault-block {
    margin: 0.2em 0;
  }
}

.ticketSupport__container {
  max-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.ticket__wrapper {
  background-color: #f5f5f5;
  width: 100%;
  display: flex;
  flex: 1;
  overflow: hidden;
    height: calc(100vh - 160px);
  padding: 20px;
  font-family: "Montserrat";

  & .rdg-light {
    width: 100%;
    height: calc(100vh - 130px) !important;

    .rdg-header-row {
      background: #f0f0f0;
      width: 100%;

      .notificationHeader {
        height: 100%;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        border: none;
      }

      .headerStyles {
        color: #252525;
        font-family: "Montserrat";
        border: none;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}

.container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
}

.status-container {
  height: 80%;
  display: flex;
  align-items: center;
  border-radius: 3px;
  z-index: 1000;
  transition: 0.3s linear;

  .status-progressing {
    position: absolute;
    z-index: -1;
    height: 100%;
    transition: 0.3s linear;
  }
}

.status-icon-container {
  width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.status-text {
  font-weight: 600;
  padding: 10px;
  color: #fff;
}

.custom-pagination-wrapper {
  width: 100%;
  height: 40px;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.custom-pagination {
  width: 20%;
  height: 50%;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 500;

  .three-dots-icon {
    display: flex;
    gap: 10px;
    align-items: baseline;
  }

  &-information {
    font-family: 'Montserrat';
    font-size: 12px;
    color: #8c8c8c;
  }
}

.popover__actions-wrapper {
  &>ul {
    min-width: 200px;
    padding: 5px;
    background-color: #fff;
    border-radius: 5px;
    color: #252525;
    display: flex;
    flex-direction: column;
    font-size: 12px;

    &>.ant-dropdown-menu-item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // gap: 20px;
      // padding: 8px 10px;
      font-family: "Montserrat";
      font-weight: 600;
      cursor: pointer;

      &:hover {
        background: rgba(108, 30, 176, 0.2);
      }

      & .action-icon {
        display: flex;
        align-items: center;
      }

      & .action-title {
        color: #252525;
        font-family: "Montserrat";
        font-size: 13px;
        font-weight: 500;
        width: 100%;
      }

      &.no-actions {
        font-weight: 600;

        span {
          color: #6C1EB0;
        }
      }
    }

    & .ant-dropdown-menu-submenu {
      & .ant-dropdown-menu-submenu-title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        padding: 8px 10px;
        font-weight: 600;

        &:hover {
          background: rgba(108, 30, 176, 0.2);
        }
      }
    }
  }
}

.ticket__wrapper {
  display: block;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
  padding: 2px !important;
}
.ticket-managment-delete{
  display: flex;
  gap: 10px;
}
.ticket{
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 20px;
}
.ticket-info{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
    &__content{
      span{
        background-color: #F5F5F5;
        padding: 7px;
        font-weight: 600;
        font-size: 12px;
        border-radius: 8px;
        text-align: left;
      }
    }
}
.ticket-header{
  h2{
    color: #6C1EB0;
    font-weight: 700;
    font-size: 20px;
  }
}
.ticket-description{
  border: 1px solid #cacaca;
  padding: 10px 20px 10px 20px;
  border-radius: 8px;
  height: 54px;
  display: flex;
  align-items: center;
  background-color: #F2F2F2;
  // color: #979797;
  color: #cbcaca;
}
.ant-picker.ant-picker-disabled{
  background-color: #fff !important;
  border-color: #cacaca !important;
} 
.draftContent .rdw-editor-main{
  height: 140px;
  min-height: auto !important;
} 
.edit-ticket-managment{
  width: 100%;
  padding: 9px;
  border-radius: 8px;
  border: none;
  border: 1px solid #d9d9d9;
  margin-bottom: 20px;
}
.rdw-editor-wrapper{
  display: flex;
  flex-direction: column-reverse;
}
.comment-editor{
  position: relative;
}
.comments-title{
  font-weight: 600;
  font-size: 14px;
}
.editticket-status{
  padding: 5px;
  border-radius: 3px;
}
.editticket-status-icon{
  margin-top: 4px;
}
.comment{
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}
.comment-comment{
  color: #979797;
}
.commentDivider{
  display: flex;
  border-top: 1px solid #D9D9D9;
  padding-top: 10px;
  align-items: baseline;
}
.commentInputContainer{
  width: 100%;
}
// .ticket-info__content{
//   font-family: "Montserrat";
//   font-weight: 600;
//   display: flex;
//   font-size: 12px;
//   align-items: center;
//   gap: 20px;
// }
.ticket-info__content {
  font-family: "Montserrat";
  font-weight: 600;
  display: flex;
  font-size: 12px;
  align-items: center;
  gap: 10px;

  &-label{
    width: 75px;
  }
  &-span{
    div {
      background-color: #f0f0f0;
      padding: 4px 8px;
      border-radius: 4px;
    }
  }
}

.dropdown__customClass{
  .MuiInputBase-input{
    padding: unset !important;
    // background-color: #F2F2F2 !important;
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child{
    padding-left: 20px !important;
    height: 50px !important;
    font-weight: 600;
    color: #979797;
  }
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input{
    padding: unset !important;
  }
  .MuiInputBase-root.Mui-disabled{
    background-color: #F2F2F2;
  }
  
}

.empty-rows-view{
    height: 100%;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Montserrat";
    color: #6C1EB0;
}