.header-component{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFFFF;
    height: 60px;
    border-bottom: 1px solid #EFEFEF;
    flex-wrap: wrap;
    &-loader {
      margin : 0 auto 0 40px;
    }
    // @media screen and (max-width: 1300px) {
    //     height: auto;
    //     flex-direction: column;
    //     align-items: flex-start;
    //     gap: 10px;
    //     padding-bottom: 10px;
    //   }
    &-wrapper{
      padding: 0 30px 0 20px;
      display: flex;
      gap: 10px;
      min-width: 130px;
      align-items: center;
      height: 60px;
      border-right: 1px solid #EFEFEF;

    }
    &-text{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      color: #6C1EB0;
    }
    .dashboard__left__text-header {
    //   @media screen and (max-width : 762px) {
    //     width : 100vw;
    //     height : fit-content;
    //     margin : 10px 0 30px 20px;
    //     display: flex;
    //     flex-direction: column;
    //     align-items: flex-start;
    //    }
    }
  }
  .dashboard__left__text-actions {
   display: flex;
   gap: 20px;
   align-items: center;
   padding-right: 20px;
  }