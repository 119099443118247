// .drawer {
//     min-width: 200px;
//     max-width: 200px;
//     height: 100vh;
//     background-color: #fff;
//     display: flex;
//     flex-direction: column;
//     justify-content: space-evenly;
//     align-items: center;
//     z-index: 100;
//     text-align: center;
//     position: fixed;
//     overflow: auto;
//     transition: 0.5s ease-in-out;
//     box-shadow: 0px 2px 15px 0px #ECEDF0;
//     @media screen and (max-width: 500px) {
//       box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
//     }
  
//     @media screen and (max-height: 1000px) {
//       padding: 0px;
//       justify-content: space-evenly;
//     }
  
//     &::-webkit-scrollbar {
//       width: 6px !important;
//       height: 7px !important;
//     }
//     &::-webkit-scrollbar-track {
//       // background: #f1f1f1;
//       background: linear-gradient(119.24deg, #9815967c 0%, #1c4ccf6f 100%);
//     }
//     &::-webkit-scrollbar-thumb {
//       // background: #1e8659;
//       background: #6C1EB0;
//       border-radius: 10px;
//     }
//     &::-webkit-scrollbar-thumb:hover {
//       // background: rgb(32, 167, 108);
//       background: #6C1EB0;
//     }
  
//     .drawer_big_container {
//       position: relative;
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       @media screen and (max-height: 1000px) {
//         margin: 0px 0;
//       }
//     }
//     .drawer-items {
//       position: relative;
//       width: 100%;
//       .drawer-items_ {
//         width: 100%;
//         display: flex;
//         align-items: center;
//         justify-content: flex-end;
//         height: 45px;
//         cursor: pointer;
//     }
//     &__tab {
//       position: absolute;
//       width: 5px;
//       background-color: #6C1EB0;
//       height: 20px;
//       top: 0;
//       right: 0;
//       transition: .4s ease-in-out all;
//       &.hide {
//         // width: 0;
//         // transition: .1s ease-in-out all;
//         display: none;
//         // top: 100px;
//       }
//     }
//     }
//     &__img {
//       width: 70px;
//       height: 70px;
//       display: block;
//       margin: 0 auto;
//       border-radius: 50%;
//       object-fit: cover;
      
//     }
//     .collapse-sidebar{
//       width: 20px;
//       height: 20px;
//       background-color: #6C1EB0;
//       align-self: flex-end;
//       cursor: pointer;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//     }
//     .drawer-settings {
//       img {
//         width: 21px;
//         height: 21px;
//         cursor: pointer;
//       }
//     }
//   }
  
  
  
//   .drawer-items__img {
//     justify-content: center;
//     cursor: pointer;
//     max-width: 50px;
//     max-height: 50px;
//     width: 21px;
//     height: 21px;
//     &.Users {
//       width: 18px;
//     }
//   }
//   .drawer-items__wrapper{
//     width: 80%;
//     display: flex;
//     align-items: center;
//     justify-content: flex-start;
//     height: 45px;
//     gap: 14px;
//     position : relative;
//     cursor: pointer;
//     .messages__topic__name__unseen {
//       width: 6px;
//       height: 5px;
//       border-radius: 50%;
//       background-color: #D46D6B;
//       position : absolute;
//       right: 25px;
//       top : 20px;
//     }
//    }
//    .drawer-items__icon{
//     display: flex;
//     align-items: center;
//     justify-content: center;
//    }
//   .drawer-items__text {
//     font-family: "Montserrat";
//     font-size: 14px;
//     text-align: left;
//     font-weight: 500;
//     color: #6C1EB0;
//   }
//   .drawer-items_tab{
//     width: 5px;
//     height: 20px;
//     background-color: red;
//     &.active{
//       background-color: #6C1EB0;
//     }
//   }
  
//   .drawer__img {
//     &.char {
//       background-color: #a797c7;
//       font-family: "Montserrat";
//       font-size: 20px;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       font-weight: 800;
//       color: white;
//     }
//   }
  
//   .dropdown-input {
//     margin-top: 15px;
//   }
  
//   // .birth-input .MuiFormLabel-root{
//   //   font-family: "Montserrat" !important;
//   //   font-size: 15px !important;
//   // }
  
  
//   .gender-dropdown-input .MuiInputLabel-root{
//     font-size: 13px; 
//   }
  
//   .gender-dropdown-input .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled{
//     position: absolute;
//     top: 22px !important;
//     left: -13px
//   }
  
//   .gender-dropdown-input .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
//     border-radius: inherit !important;
    
//   }
//   .gender-dropdown-input .MuiFormControl-root.MuiTextField-root.dropdown-option.MuiFormControl-fullWidth label {
//     top: 35px !important;
//   }
  
//   .gender-dropdown-input[aria-expanded="true"] {
//     label {
//       top: 15px !important;
//     }
//   }
  
//   // .gender-dropdown-input .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined.MuiFormLabel-filled {
//   //   top: 13px !important;
//   // }
  
//   .user-dropdown-input .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
//     border-radius: inherit !important;
//   }
//   .user-dropdown-input[aria-expanded="true"] {
//     label {
//       top: 15px !important;
//     }
//   }
  
//   .user-dropdown-input .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined.MuiFormLabel-filled {
//     top: 13px !important;
//   }
  
//   .user-dropdown-input .MuiFormControl-root.MuiTextField-root.dropdown-option.MuiFormControl-fullWidth label {
//     top: 26px ;
//   }
  
  
//   .user-dropdown-input .MuiInputLabel-root{
//     font-size: 13px; 
//   }
  
//   .user-dropdown-input .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
//     border-radius: inherit !important;
   
//   }
  
//   .user-dropdown-input .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled{
//     position: absolute;
//     bottom: 2px;
//     left: -13px
//   }
  
//   .agency-dropdown-input .MuiFormControl-root.MuiTextField-root.dropdown-option.MuiFormControl-fullWidth label {
//     top: 13px
//   }
  
//   .agency-dropdown-input .MuiInputBase-root{
//     margin-top: 18px !important;
//     border-radius: 8px !important;
//   }
  
//   .user-dropdown-input .crate__user__side__drawer.MuiFormControl-root.MuiTextField-root.dropdown-option.MuiFormControl-fullWidth label{
//     top: 26px !important;
//   }
  
  
//   .company-dropdown-input .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled{
//     position: absolute;
//     bottom: 2px;
//     left: -13px
//   }
  
//   .company-dropdown-input .MuiFormControl-root.MuiTextField-root.dropdown-option.MuiFormControl-fullWidth label {
//     top: 25px;
//   }
  
//   .company-dropdown-input .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline{
//     border-radius: 8px !important;
//   }
  
//   .company-dropdown-input[aria-expanded="true"] {
//     label {
//       top: 15px !important;
//     }
//   }
  
//   .company-dropdown-input .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined.MuiFormLabel-filled {
//     top: 13px !important;
//   }
  
  
//   .crate__user__side__drawer {
//     width: calc(100% - 200px);
//     background-color: #fdfdfd;
//     display: flex;
//     justify-content: center;
//     width: 100%;
  
//     & .edit__and__create__user__container {
//       width: 100%;
//       display: flex;
//       justify-content: center;
//       align-items: center;
  
//       .create__user__form__ContGeneral {
//         display: flex;
//         align-items: flex-start;
//         width: 100%;
//         // border-bottom: 0.5px solid rgb(188, 188, 188);
//         // background: #efefef;
//         background: #44228b1a;
//         padding: 15px;
//         margin-bottom: 10px;
//         border-radius: 5px;
//         .MuiInputBase-root {
//           background-color: white;
//           border-radius: 8px;
//           // margin-top: 26px;
//         }
//         .form_input .form_input__label{
//           color:#6C1EB0;
//         }
//         .create__user__form__span{
//           color:#6C1EB0;
//         }
//         &-left {
//           display: flex;
//           flex-wrap: wrap;
//           width: 80%;
//           gap: 10px;
  
//           &-leftInputs{
//             width: 100%;
//             display: flex;
//             flex-direction: column;
  
//             &-up{
//               display: flex;
//               gap: 10px;
//             }
//             &-down{
//               display: flex;
//               gap: 10px;
//             }
//           }
  
//           & .MuiFormControl-root.MuiTextField-root {
//             margin-top: 0px !important;
//             background-color: white;
//           }
//           & .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
//             font-family: "Montserrat" !important;
//             font-size: 13px !important;
//             // top: -9px !important;
//             // left: -10px;
//           }
//         }
//         &-right {
//           // margin-top: 71px;
//           width: 40%;
//           display: flex;
//           flex-direction: column;
//           gap: 10px;
//           margin-top: 60px;
//           .dropdown-input {
//             width: 100% !important;
//           }
//           & .form_input,
//           & .MuiTextField-root,
//           & .create__user__input__file,
//           & .MuiFormControl-root {
//             width: 100% !important;
//           }
//         }
//         &-right__down {
//           margin-top: 60px;
//           width: 40%;
//           display: flex;
//           flex-direction: column;
//           gap: 7px;  
//           .dropdown-input {
//             width: 100% !important;
//           }
//           & .form_input,
//           & .MuiTextField-root,
//           & .create__user__input__file,
//           & .MuiFormControl-root {
//             width: 100% !important;
//           }
//         }
        
//       }
//       .create__user__form-notShow {
//         width: 40%;
//         // background-color: #efefef !important;
//         background: transparent; 
//         .spantest {
//           display: flex;
//           width: 100%;
//           height: 45px;
//           align-items: center;
//           justify-content: flex-start;
//           padding-left: 15px;
//           background: #44228b40;
//           box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
//           border-radius: 5px;
//         }
//         &-text {
//           font-size: 13px;
//           display: flex;
//           align-items: flex-end;
//           justify-content: flex-start;
//           font-family: "Montserrat";
//           height: 100%;
//           color: #6C1EB0;
//         }
//         .form_input input {
//           margin-top: 0px !important;
//           background-color: #efefef !important;
//           width: 100% !important;
//           border: none;
//           outline: none;
//           color: #6C1EB0;
//         }
//       }
//       & .edit__and__create__form {
//         width: 90% !important;
//         // padding: 25px !important;
//         // background: #dae1f335;
//         border-radius: 4px;
  
//         .isCompany__Wrapper {
//           display: flex;
//           flex-direction: column;
//           align-items: flex-start;
//           justify-content: space-between;
//           width: 100%;
//           // background: #efefef;
//           background: #44228b1a;
//           padding: 15px;
//           margin-bottom: 10px;
//           border-radius: 5px;
//           .company__inputs {
//             display: flex;
//             align-items: center;
//             justify-content: flex-start;
//             width: 70%;
//             .dropdown-input {
//               margin-bottom: 8px;
//             }
//           }
//         }
//         & .back__button__create__user {
//           width: 100%;
//           height: 40px;
//           margin-bottom: 20px;
//         }
//         & .back__button {
//           width: 40px;
//           cursor: pointer;
//           top: -20px;
//           left: 0;
//           padding-top: 10px;
//         }
//       }
  
//       & .edit__and__create__form {
//         width: 60%;
//         flex-wrap: wrap;
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
  
//         & .MuiFormControl-root.MuiTextField-root {
//           background-color: transparent;
//         }
  
//         & .MuiAutocomplete-root {
//           width: 40%;
//           border: solid 2px #f5f5f5;
//           padding-right: 0;
//           // margin-left: 5px;
  
//           // & div{
//           //   width: 100%;
//           //   & div{
//           //     width: 100%;
//           //   }
//           // }
//         }
//       }
//     }
//     &
//       .MuiFormControl-root.MuiTextField-root.dropdown-option.MuiFormControl-fullWidth {
//       width: 100% !important;
//       font-size: 13px;
//       border: none;
//       & label {
//         left: -7px;
//         color: #6C1EB0;
//         top: 4px;
//       }
//       & :focus-visible {
//         outline: none !important;
//       }
//     }
//     &
//       .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
//       width: 100%;
//       height: 45px;
//       border: none;
//       background-color: white;
//     }
//     & .MuiFormControl-root.MuiTextField-root {
//       padding-right: 0;
//       padding: 0;
//     }
//     & .form_input,
//     & .MuiTextField-root,
//     & .create__user__input__file,
//     & .MuiFormControl-root {
//       width: 40% !important;
//       & input {
//         font-size: 13px !important;
//         outline: none;
//       }
//     }
//     & .MuiFormGroup-root {
//       & legend {
//         padding-top: 10px;
//       }
//       & div {
//         margin-left: 10px;
//       }
//     }
//     & .MuiInputBase-root {
//       border: solid 2px #f5f5f5;
//       height: 45px;
//       margin-top: 30px;
//       width: 100% !important;
  
//       & input {
//         width: 100%;
//         background-color: #fff !important;
//         font-size: 13px;
//         padding-right: 5px;
//         padding-left: 10px;
//         outline: none;
//       }
//       & div {
//         & button {
//           padding-right: 20px;
//         }
//       }
//     }
//     & .MuiFormControl-root {
//       & legend {
//         font-size: 12px;
//         margin-bottom: 5px;
//         font-family: "Montserrat";
//         color:#6C1EB0;
//       }
//     }
//     & .submit__user {
//       width: 40%;
//       border-radius: 5px;
//     }
//   }
  
//   .create__user__input__file {
//     color: #6C1EB0;
//     // color: #6C1EB0;
//   }
  
//   .back__button_client {
//     width: 40px;
//     cursor: pointer;
//     top: -20px;
//     left: 0;
//   }
  
//   .back__button__order_pdf {
//     display: flex;
//     align-self: flex-start;
//   }
  
//   .hideDrawer {
//     @media screen and (max-width: 500px) {
//       transform: translateX(-200px);
//       transition: 0.5s ease-in-out;
//       box-shadow: none;
//     }
//   }
  
  
//   // .mobileEmail{
//   //     margin-top: 60px !important;
//   // }

.drawer {
  min-width: 100px;
  max-width: 100px;
  height: 100vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  z-index: 100;
  text-align: center;
  position: fixed;
  overflow: auto;
  transition: 0.5s ease-in-out;
  box-shadow: 0px 2px 15px 0px #ecedf0;
  animation-name: collapse;
  animation-fill-mode: forwards;
  animation-duration: 0.5s;
  &__imgcontent {
    width: 100%;
    height: 70px;
    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 0;
      padding: 0 20px;
    }
  }
  &.collapse {
    min-width: 220px;
    max-width: 220px;
  }
  @media screen and (max-width: 500px) {
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }

  @media screen and (max-height: 1000px) {
    padding: 0px;
    justify-content: space-evenly;
  }

  &::-webkit-scrollbar {
    width: 6px !important;
    height: 7px !important;
  }
  &::-webkit-scrollbar-track {
    // background: #f1f1f1;
    background: linear-gradient(119.24deg, #9815967c 0%, #1c4ccf6f 100%);
  }
  &::-webkit-scrollbar-thumb {
    // background: #1e8659;
    background: #6C1EB0;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    // background: rgb(32, 167, 108);
    background: #6C1EB0;
  }

  .drawer_big_container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin: 30px 0;
    @media screen and (max-height: 1000px) {
      margin: 0px 0;
    }
  }

  .drawer-items {
    position: relative;
    width: 100%;
    .drawer-items_ {
      overflow: hidden;
      &_text {
        width: 95px;
        min-width: 95px;
        color: #6C1EB0;
        animation-name: showText;
        animation-fill-mode: forwards;
        animation-duration: 0.5s;
        span {
        }
      }
    } 
    &.collapse {
      .drawer-items_ {
        overflow: hidden;
        &_text { 
          width: 95px;
          min-width: 95px;
          animation-name: hideText;
          color: #6C1EB0;
          animation-fill-mode: forwards;
          animation-duration: 0.5s;
        }
      } 
    }
    .drawer-items_ {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 45px;
      margin: 0 20px;
      &.active {
        border-radius: 4px;
        background: #6C1EB0;
        // margin: 0 5px;
        & .drawer-items__wrapper {
          .drawer-items__icon {
            svg {
              & > * {
                fill: #fff;
              }
            }
          }
          .drawer-items__text {
            span {
              color: #fff;
            }
          }
        }
      }
      &_icon {
        svg {
          & > * {
            fill: #6C1EB0;
          }
        }
      }
    }
    // &__tab {
    //   position: absolute;
    //   width: 5px;
    //   background-color: #6C1EB0;
    //   height: 20px;
    //   top: 0;
    //   right: 0;
    //   transition: 0.4s ease-in-out all;
    //   &.hide {
    //     // width: 0;
    //     // transition: .1s ease-in-out all;
    //     display: none;
    //     // top: 100px;
    //   }
    // }
  }
  &__img {
    width: 70px;
    height: 70px;
    display: block;
    margin: 0 auto;
    border-radius: 50%;
    object-fit: contain;
  }
  .collapse-sidebar {
    align-self: flex-end;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      & rect {
        fill: #6C1EB0;
      }
    }
  }
  .drawer-settings {
    img {
      width: 21px;
      height: 21px;
      cursor: pointer;
    }
  }
}
@keyframes collapse {
  from {
    width: 100px;
   }
   to {
    width: 220px;
   }
 }
@keyframes hideText {
 0% {
  //  visibility: visible;
  display: block;
  }
  100% {
    // visibility: hidden;
    display: none;
  }
}
@keyframes showText {
 0% {
    // visibility: hidden;
    display: none;
  }
  100% {
    // visibility: visible;
    display: block;
  }
}

.drawer-items__text {
  font-family: 'Montserrat';
  font-size: 14px;
  text-align: left;
  font-weight: 500;
  color: #6C1EB0;
  // transform: scale(1);
  display: block;
  transition: all 0.3s ease-in-out;
}

.drawer-items__img {
  justify-content: center;
  cursor: pointer;
  max-width: 50px;
  max-height: 50px;
  width: 21px;
  height: 21px;
  &.Users {
    width: 18px;
  }
}
.drawer-items__wrapper {
  width: 100%;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 45px;
  gap: 14px;
  position: relative;
  cursor: pointer;
  .messages__topic__name__unseen {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #d13532;
    position: absolute;
    right: 25px;
    top: 20px;
  }
}
.drawer-items__icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.drawer-items_tab {
  width: 5px;
  height: 20px;
  background-color: red;
  &.active {
    background-color: #6C1EB0;
  }
}

.drawer__img {
  &.char {
    background-color: #6C1EB0;
    font-family: 'Montserrat';
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    color: white;
  }
}

.dropdown-input {
  margin-top: 15px;
}

// .birth-input .MuiFormLabel-root{
//   font-family: "Montserrat" !important;
//   font-size: 15px !important;
// }

.gender-dropdown-input .MuiInputLabel-root {
  font-size: 13px;
}

.gender-dropdown-input
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
  position: absolute;
  top: 22px !important;
  left: -13px;
}

.gender-dropdown-input .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-radius: inherit !important;
}
.gender-dropdown-input
  .MuiFormControl-root.MuiTextField-root.dropdown-option.MuiFormControl-fullWidth
  label {
  top: 35px !important;
}

.gender-dropdown-input[aria-expanded='true'] {
  label {
    top: 15px !important;
  }
}

// .gender-dropdown-input .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined.MuiFormLabel-filled {
//   top: 13px !important;
// }

.user-dropdown-input .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-radius: inherit !important;
}
.user-dropdown-input[aria-expanded='true'] {
  label {
    top: 15px !important;
  }
}

.user-dropdown-input
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined.MuiFormLabel-filled {
  top: 13px !important;
}

.user-dropdown-input
  .MuiFormControl-root.MuiTextField-root.dropdown-option.MuiFormControl-fullWidth
  label {
  top: 26px;
}

.user-dropdown-input .MuiInputLabel-root {
  font-size: 13px;
}

.user-dropdown-input .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-radius: inherit !important;
}

.user-dropdown-input
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
  position: absolute;
  bottom: 2px;
  left: -13px;
}

.agency-dropdown-input
  .MuiFormControl-root.MuiTextField-root.dropdown-option.MuiFormControl-fullWidth
  label {
  top: 13px;
}

.agency-dropdown-input .MuiInputBase-root {
  margin-top: 18px !important;
  border-radius: 8px !important;
}

.user-dropdown-input
  .crate__user__side__drawer.MuiFormControl-root.MuiTextField-root.dropdown-option.MuiFormControl-fullWidth
  label {
  top: 26px !important;
}

.company-dropdown-input
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
  position: absolute;
  bottom: 2px;
  left: -13px;
}

.company-dropdown-input
  .MuiFormControl-root.MuiTextField-root.dropdown-option.MuiFormControl-fullWidth
  label {
  top: 25px;
}

.company-dropdown-input
  .MuiOutlinedInput-root
  .MuiOutlinedInput-notchedOutline {
  border-radius: 8px !important;
}

.company-dropdown-input[aria-expanded='true'] {
  label {
    top: 15px !important;
  }
}

.company-dropdown-input
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined.MuiFormLabel-filled {
  top: 13px !important;
}

.crate__user__side__drawer {
  width: calc(100% - 220px);
  background-color: #fdfdfd;
  display: flex;
  justify-content: center;
  width: 100%;

  & .edit__and__create__user__container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .create__user__form__ContGeneral {
      display: flex;
      align-items: flex-start;
      width: 100%;
      // border-bottom: 0.5px solid rgb(188, 188, 188);
      // background: #efefef;
      background: #ECECEC;
      padding: 15px;
      margin-bottom: 10px;
      border-radius: 8px;
      gap: 6%;
      box-shadow: 2px 3px 4px 0px rgba(0, 0, 0, 0.15);
      ;
      .MuiInputBase-root {
        background-color: white;
        border-radius: 8px;
        // margin-top: 26px;
      }
      .form_input .form_input__label {
         color: #252525;
         font-weight: 600;
         font-size: 12px;
      }
      .create__user__form__span {
        color: #252525;
        font-size: 14px;
        font-weight: 700;
      }
      &-left {
        display: flex;
        flex-wrap: wrap;
        width: 89%;
        gap: 10px;
        justify-content: space-between;

        &-leftInputs {
          width: 100%;
          display: flex;
          flex-direction: column;

          &-up {
            display: flex;
            justify-content: space-between;
            gap: 10px;
          }
          &-down {
            display: flex;
            gap: 10px;
          }
        }

        & .MuiFormControl-root.MuiTextField-root {
          margin-top: 0px !important;
          background-color: white;
        }
        &
          .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
          font-family: 'Montserrat' !important;
          font-size: 15px !important;
          font-weight: 600;
          // top: -9px !important;
          // left: -10px;
        }
      }
      &-right {
        // margin-top: 71px;
        width: 40%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 57px;
        .dropdown-input {
          width: 100% !important;
        }
        & .form_input,
        & .MuiTextField-root,
        & .create__user__input__file,
        & .MuiFormControl-root {
          width: 100% !important;
        }
      }
      &-right__down {
        margin-top: 60px;
        width: 40%;
        display: flex;
        flex-direction: column;
        gap: 7px;
        .dropdown-input {
          width: 100% !important;
        }
        & .form_input,
        & .MuiTextField-root,
        & .create__user__input__file,
        & .MuiFormControl-root {
          width: 100% !important;
        }
      }
    }
    .create__user__form-notShow {
      width: 45%;
      // background-color: #efefef !important;
      background: transparent;
      .spantest {
        display: flex;
        width: 100%;
        height: 45px;
        align-items: center;
        justify-content: flex-start;
        padding-left: 15px;
        background: rgba(218, 218, 218, 1);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1) inset;
        border-radius: 5px;
      }
      &-text {
        font-size: 12px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        font-family: 'Montserrat';
        height: 100%;
        color: #979797;
        ;
      }
      .form_input input {
        margin-top: 0px !important;
        background-color: #efefef !important;
        width: 100% !important;
        border: none;
        outline: none;
        color: #6C1EB0;
      }
    }
    & .edit__and__create__form {
      width: 90% !important;
      // padding: 25px !important;
      // background: #dae1f335;
      border-radius: 4px;

      .isCompany__Wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        // background: #efefef;
        background: #44228b1a;
        padding: 15px;
        margin-bottom: 10px;
        border-radius: 5px;
        .company__inputs {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: 70%;
          .dropdown-input {
            margin-bottom: 8px;
          }
        }
      }
      & .back__button__create__user {
        width: 100%;
        height: 40px;
        margin-bottom: 20px;
      }
      & .back__button {
        width: 20px;
        cursor: pointer;
        top: -20px;
        left: 0;
        padding-top: 10px;
      }
    }

    & .edit__and__create__form {
      width: 60%;
      flex-wrap: wrap;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & .MuiFormControl-root.MuiTextField-root {
        background-color: transparent;
      }

      & .MuiAutocomplete-root {
        width: 40%;
        border: solid 2px #f5f5f5;
        padding-right: 0;
        // margin-left: 5px;

        // & div{
        //   width: 100%;
        //   & div{
        //     width: 100%;
        //   }
        // }
      }
    }
  }
  &
    .MuiFormControl-root.MuiTextField-root.dropdown-option.MuiFormControl-fullWidth {
    width: 100% !important;
    font-size: 13px;
    border: none;
    & label {
      left: -7px;
      color:#252525;
      font-weight: 600;
      top: 4px;
    }
    & :focus-visible {
      outline: none !important;
    }
  }
  &
    .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
    width: 100%;
    height: 45px;
    border: none;
    background-color: white;
    margin-top: 12px !important;
    & .MuiSvgIcon-root{
      color:#D9D9D9 ;
    }
  }
  & .MuiFormControl-root.MuiTextField-root {
    padding-right: 0;
    padding: 0;
  }
  & .form_input,
  & .MuiTextField-root,
  & .create__user__input__file,
  & .MuiFormControl-root {
    width: 45% !important;
    & input {
      font-size: 13px !important;
      outline: none;
    }
  }
  & .MuiFormGroup-root {
    & legend {
      padding-top: 10px;
    }
    & div {
      margin-left: 10px;
    }
  }
  & .MuiInputBase-root {
    border: solid 1px #D9D9D9;
    height: 45px;
    margin-top: 35px;
    width: 100% !important;
    & .css-i4bv87-MuiSvgIcon-root{
      color: #c1c1c1 !important;
    }
    & input {
      width: 100%;
      background-color: #fff !important;
      font-size: 13px;
      padding-right: 5px;
      padding-left: 10px;
      outline: none;
    }
    & div {
      & button {
        padding-right: 20px;
      }
    }
  }
  & .MuiFormControl-root {
    & legend {
      font-size: 12px;
      margin-bottom: 5px;
      font-family: 'Montserrat';
      color: #252525;
      font-weight: 600;
    }
  }
  & .submit__user {
    width: 40%;
    border-radius: 5px;
  }
}

.create__user__input__file {
  color: #6C1EB0;
  // color: #6C1EB0;
}

.back__button_client {
  width: 40px;
  cursor: pointer;
  top: -20px;
  left: 0;
}

.back__button__order_pdf {
  display: flex;
  align-self: flex-start;
}

.hideDrawer {
  @media screen and (max-width: 500px) {
    transform: translateX(-200px);
    transition: 0.5s ease-in-out;
    box-shadow: none;
  }
}

// .mobileEmail{
//     margin-top: 60px !important;
// }

.user__and__logout {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #d9d9d9;
  gap: 10px;
  overflow: hidden;
  .user__infos {
    display: flex;
    align-items: center;
    gap: 5px;
    & img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      object-fit: cover;
      cursor: pointer;
    }
    .drawer__img {
      width: 30px;
      height: 30px;
      span {
        font-size: 12px;
        font-family: "Montserrat";
      }
    }
    &-name {
      color: #6C1EB0;
      font-family: 'Montserrat';
      font-size: 12px;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;   
    }
  }
  .logout__user {
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
      & > * {
        fill: #6C1EB0;
      }
    }
  }
}
.dates-picker{
  & .MuiInputLabel-formControl{
    position: unset !important;
    transform: translate(0, 0) scale(1) !important;
    font-size: 12px;
    font-weight: 600;
    color: #252525;
  }
  & .MuiInputBase-root{
    margin-top: 10px !important;
  } 
  & .MuiFormControl-root.MuiTextField-root{
    margin-top: 14px !important;
  }
}
.dates-picker-delete{
    position: relative !important;
}
  
  