.user__container {
    max-height: 100vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .userTicket__wrapper{
    font-weight: 520;
    background-color: #f5f5f5;
    width: 100%;
    display: flex;
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    font-family: "Montserrat";
    & .rdg-light {
      width: 100%;
      .rdg-header-row {
        background: #f0f0f0;
        .notificationHeader {
          height: 100%;
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: center;
          border: none;
        }
        .headerStyles {
          color: #252525;
          font-family: "Montserrat";
          border: none;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }
  .user__table__photo__and__name {
    display: flex;
    align-items: center;
    cursor: pointer;
  
    & img {
      margin-right: 10px;
      min-width: 40px;
      width: 40px;
      min-height: 40px;
      height: 40px;
      border-radius: 100%;
      background-color: white;
      padding: 3px;
    }
    
  }
  .user__username{
    color:#6C1EB0 ;
    font-weight: 600;
     text-decoration: underline;
  }
  .rdg-row-odd{
    background-color: #E4E4E4 !important;
  }
  .rdg-header-row{
    background-color: #E4E4E4 !important;
  }
  .user__table__initials {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    min-width: 40px;
    width: 40px;
    min-height: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: #a797c7;
    color: white;

    &-details{
          display: flex;
          flex-direction: column;
          gap: 5px;
    }
}
.userTicket__wrapper{
  display: block;
}
