.form_input {
  // padding: 5px 0;

  & .form_input__label {
    font-family: "Montserrat";
    margin-left: 1px;
    color: #252525;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  input {
    width: 100%;
    padding: 16px 16px 16px 12px;
    border: solid 1px #cacaca;
    background-color: #fff !important;
    font-size: 13px;
    color: #6C1EB0;
    border-radius: 5px;

    
  }
  .bottomBreak {
    position: relative;
    float: left;
    width: 100%;
    height: 2px;
    margin-top: 5px;
    margin-bottom: 5px;
    border: 1px dotted #b3b0b0;
  }
}
.form_input__label {
  font-family: "Montserrat";
  font-size: 11px;
  margin-left: 1px;
  color: #7d8c96;
}
#upload-photo {
  padding-top: 5px;
}

// INPUT FILE

.input__type__file__bordered {
  width: 100%;
  height: 45px;
  margin: 8.5px 0 0;
  // padding: 16px 16px 16px 12px;
  border: dashed 2px #efefef;
  border-bottom: none;
  background-color: #fff;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-family: "Montserrat";
  border-radius: 8px;

  label {
    height: 45px;
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 15px;
    font-size: 13px;
  }
}
.input__type__file__bordered__name {
  width: 100%;
  height: 37px;
  border-radius: 2px;
  background-color: #f6f6f6;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-family: "Montserrat";
  padding: 16px 16px 16px 12px;
  border: dashed 2px #efefef;
  border-top: none;
}
.input-description{
  color: #1e8659;
  font-size: 11px;
}

.create__user__form__span {
  width: 100%;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  color: #26353e;
  margin: 20px 0 5px 0;
}

.create__user__form__button {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.create_user_company_section {
  display: flex;
  flex-direction: row;
  // flex-wrap: nowrap;
  width: 100%;
  // justify-content: space-between;
}

.create_user_company_inputs {
  display: flex;
  flex-direction: row;
  // align-items: center;
  // justify-content: space-evenly;
  width: 100%;
  gap: 10px;

  
}

.create_user_agency_settings {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  gap: 30px;
}
.preview__uploaded__logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 20;
}
.custom__input__popover {
  z-index: 999999999;
  & ul {
    border-radius: 8px;
   li {
    font-family: "Montserrat";
    font-size: 12px;
    color: #252525;
    &:hover {
      background-color: transparent;
    }
   }
  }
}