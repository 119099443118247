.auth {
    background-color: #fff;
    font-family: "Montserrat";
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;
  
    .token__invalid__div{
      width: 100vw;
      height: 100vh;
      background-color: #f5f5f5;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #1e8659;
      font-family: "Montserrat";
      & img{
        width: 105px;
   
      }
      & h1{
        margin-top: 40px;
        margin-bottom: 10px;
        font-family: "Montserrat";
        font-size: 18px;
        font-weight: bold;
        color: #26353e;
      }
      & p{
        cursor: pointer;
        font-family: "Montserrat";
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: #1e8659;
        text-decoration: underline;
    
  
      }
  
    }
    .left{
      width: 55%;
      height: 100%;
      background: #6C1EB0;
      display: flex;
      align-items: center;
      justify-content: center;
      backdrop-filter: blur(50.4175px);
    }
    .middle {
      // position: absolute;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
      margin: 0 auto;
      width: 350px;
      height: auto;
      padding: 20px 30px;
      background: #FFFFFF;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.107441);
      border-radius: 6px;
      form {
        display: flex;
        flex-direction: column;
        padding: 0 30px;
      }
     
  
  
  
      .form_input  {
          padding: 3px 0;
          margin-bottom: 20px;
          
         
          input {
              margin-top: 2px;
              border: 1px solid;
              border-color: #981596;
              border-radius: 2px;
              // border: 1px solid #9D8BC0;
          }
      }
    }
  
    .title {
      width: 100%;
      display: block;
      margin: 0 0 50px 9px;
      font-size: 35px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #26353e;
      text-align: center;
    }
  
    .authBtn {
        width: 100%;
        padding: 13px 0;
        // background-color: #1e8659;
        display: block;
        color: #fff;
        font-size: 13px;
        margin-top: 11px;
        background: #6C1EB0;
        border-radius: 5px;
  
        &.disabled{
          background-color: rgb(160, 160, 160);
          cursor: not-allowed;
        }
  
    }
  
    .login-bottom {
      justify-content: space-between;
      display: flex;
        text-align: center;
        // color: #1e8659;
        color: #0E88F2;
        & > * {
            display: block;
            cursor: pointer;
        }
    }
  
    .forgot {
        margin: 18px 0;
       
        font-size: 12px;
  
  
    }
  
    .no-account {
      color: #7d8c96;
      font-size: 12px;
  
    }
  
    .create-account {
      margin-top: 10px;
      font-size: 13px;
  
  
    }
  
  
  
    .terms-privacy {
        color: #808f98;
        margin: 20px 0 30px 0;
        display: block;
        font-size: 12px;
        cursor: default;
  
  
  
        span {
            color: #1e8659;
            cursor: pointer;
        }
    }
  
    .forgot-password {
  
      &__title {
          text-align: left;
          font-size: 18px;
          font-weight: bold;
      }
  
      &__text {
          font-size: 14px;
          color: #3b505d;
          margin: 15px 0 25px 0;
  
       
      }
    }
  
  }