.delete__modal__component{
    // width: 470px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    .users__dropdown{
        margin: 0;
    }
    &__autocomplete {
        width: 80%;
        padding: 0;
        margin-top: 0 !important;
    }

    & h1{
        padding-top: 50px;
        font-family: "Montserrat";
        font-size: 17px;
        color: rgb(41, 41, 41);
        padding-left: 40px;
        font-weight: 700;
        color: #6C1EB0
    }

    & div{
        margin-top: -20px;
        padding:50px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .cancel__button{
            height: 50px;
            width: 120px;
            border: 2px solid;
            border: 1px solid #9D8BC0;
            color: #6C1EB0;
            font-family: "Montserrat";
            background-color: white;
            font-size: 17px;
            border-radius: 8px;
            font-weight: 600;
            cursor: pointer;
          
        }
        .confirm__button{
            margin-left: 10px;
            height: 50px;
            width: 120px;
            border: 2px solid;
            border: 1px solid #9D8BC0;
            // background: linear-gradient(45deg, #981596, #1C4CCF) 1;
            background:  linear-gradient(95deg, #6C1EB0 0%, #400077 100%)#6C1EB0;
            color:white;
            font-family: "Montserrat";
            // background-color: #1C4CCF;
            font-size: 17px; 
            border-radius: 8px;
            font-weight: 600;
            cursor: pointer;

        }
    }
}