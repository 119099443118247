.big__search__bar__container {
    flex-direction: row-reverse;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:10px;
    max-height: 60px;
    height: 36px;
    background: #fff;
    
    .search__input {
      background-color: #F0F0F0;
      border-radius: 8px;
      // width : 80%;
      height:100%;
      display: flex;
      flex-direction: row-reverse;
      gap: 10px;
      align-items: center;
      // padding: 5px;
      .clear_input_icon {
        cursor:pointer;
      }
    }
   
    .searchbar-filters{
      // background-color: white;
      background-color: #F0F0F0;
      display: flex;
      align-items: center;
      height:100%;
      width: 36px;
      justify-content: center;
      padding:5px;
      border-radius: 50%;
      cursor: pointer;
      &:hover{
      background-color: #b993d9;
      }
      img{
        width: 18px;
        height: 18px;
      }
    }
    & input {
      // flex: 1; 
      // padding: 0px 10px 0px 10px;
      outline: none;
      border: none;
      background: transparent;
      font-family: "Montserrat";
      font-size: 12px;
      font-weight: 500;
      width: 85%;
      // line-height: 1.67;
      color: #3b505d;
  
      &::placeholder {
        // opacity: 0.5;
        color: #C1C1C1;
        font-weight: 600;
        // color:red;
      }
    }
  }
  