@import "/src/styles/Login.scss";
@import "/src/styles/InputComponent.scss";
@import "/src/styles/Drawer.scss";
@import "/src/styles/Header.scss";
@import "/src/styles/BigSearchBar.scss";
@import "/src/styles/User.scss";
// @import "/src/styles/Ticket-Management.scss";
@import "/src/styles/TicketManagement.scss";
@import "/src/styles/DeleteModal.scss";
@import "/src/styles/Popover.scss";
@import "/src/styles/No-ApiKey.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.parent {
  // z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.parent-container {
  width: 100%;
}
p{
  margin-bottom: 0px !important;
}

.language__dropdown {
  position: absolute;
  bottom: 5px;
  right: 5px;
}
.pl30 {
  padding-left: 30px;
}
.pr30 {
  padding-right: 30px;
}
.pr20 {
  padding-right: 20px;
}
.pl20 {
  padding-left: 20px;
}

.errorClass {
  display: block;
  margin-top: 1px;
  margin-left: 5px;
  color: #D00A12;
  font-family: "Montserrat";
  font-size: 10px;
  font-style: italic;
  font-weight: 600;
  line-height: normal
}

.form_input {
  .dashboardInput {
    margin-top: 0px;
    height: 44px;
    color: #252525;
    outline: none;
    background: #ffffff;
    font-weight: 500;
    // box-shadow: 0px 4px 4px rgba(60, 60, 60, 0.1);
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(60, 60, 60, 0.1);
  }
}

.secondStep__input input{
  height: 54px !important;
  margin-top: 0px !important;
  font-size: 14px;
}

.MuiBox-root .MuiBox-root-2{
  box-shadow: unset
}
.ant-popover {
  z-index: 1301;
}

.date-picker-with-types {
  &__input {
    width: 100%;
    height: 36px;
    border-radius: 8px;
    border: 1px solid #cacaca;
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
      padding: 15px;
      color: #979797;
      font-family: "Montserrat";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.date-picker-types {
  display: flex;
  flex-direction: column;
  width: 280px;
  // height: 340px;
  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  .ant-picker {
    // display: none;
    top: 50px;
    position: absolute;
    visibility: hidden;
  }
}

.color-picker-with-popover {
  &__input {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #e8e8e8;
    display: flex;
    align-items: center;
    cursor: pointer;

    &-condition {
      display: flex;
      justify-content: center;
      align-items: center;

      &-value {
        width: 20px;
        height: 20px;
        border-radius: 15px;
        margin-left: 20px;
      }
    }

    span {
      padding-left: 15px;
      color: #979797;
      font-family: Montserrat;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
.color-picker-popover {
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 260px;

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  .ant-picker {
    // display: none;
    top: 50px;
    position: absolute;
    visibility: hidden;
  }
}
.ant-popover {
  z-index: 1301;
}
.ant-picker-dropdown {
  z-index: 1302 !important;
}

.MuiOutlinedInput-root{
  border-radius: 8px !important;
}

.Toastify {
  z-index: 99999999;
}


.rdg-cell{
  font-weight: 600 !important;
}